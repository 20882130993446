import { Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'
import { CompletedMatches } from '@/services/CompleteMatchDataService'

export default class CompletedMatchScore extends Vue {
  private routeData = useRoute()
  private matchScoreData = []
  private currentMatch = 0

  public async getSelectedMatchScore () : Promise<void> {
    await CompletedMatches.CompletedMatchScoreDataService.getCompletedMatchScore(Number(this.routeData.params.matchSetId))
      .then((response) => {
        this.matchScoreData = response.data
      })
      .catch((error) => {
        console.log('COMPLETED_MATCHES_ERROR...', error)
      })
  }

  public checkForRoundPlayed (index: number, homeOrAway: number) : boolean {
    // if (index === (this as any).matchScoreData[this.currentMatch]?.kamp_saet_scores.length - 1) {
    if (index === 0) {
      // For showing the latest score on top.
      (this as any).matchScoreData[this.currentMatch].kamp_saet_scores.sort((a: any, b: any) => {
        return b.position - a.position
      })
      let realround = (this as any).matchScoreData[this.currentMatch].realRoundsPlayed
      realround = typeof (realround) === 'string' ? JSON.parse(realround) : realround

      if ((this as any).matchScoreData[this.currentMatch].kamp_saet_scores[index].position > realround[homeOrAway]) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  public sortTheMatchScoreOnPosition (arr: any) : any {
    arr.sort((a: any, b: any) => {
      return b.position - a.position
    })
    return arr
  }

  public setPlayersNameFormate (nameStr: any) : string {
    const nameArr = nameStr.split(',')
    if (nameArr.length > 1) {
      const nameSubArr0 = nameArr[0].split(' ')
      const nameSubArr1 = nameArr[1].split(' ')
      const firstName0 = nameSubArr0[0].split('')[0]
      const firstName1 = nameSubArr1[0].split('')[0]
      nameSubArr0.shift()
      nameSubArr1.shift()
      return firstName0 + ' ' + nameSubArr0.join(' ') + '\n' + firstName1 + ' ' + nameSubArr1.join(' ')
    } else {
      const nameSubArr = nameArr[0].split(' ')
      const firstName = nameSubArr[0].split('')[0]
      nameSubArr.shift()
      // return firstName + ' ' + nameSubArr[0]
      return firstName + ' ' + nameSubArr.join(' ')
    }
  }

  public threeDartAvg (teamType: number) : any {
    let realround = (this as any).matchScoreData[this.currentMatch].realRoundsPlayed
    realround = typeof (realround) === 'string' ? JSON.parse(realround) : realround
    let threeDartAvgVal = 0

    if (teamType === 1) {
      (this as any).matchScoreData[this.currentMatch].kamp_saet_scores.map((score: any) => {
        threeDartAvgVal += score.homePlayerScore
      })
      threeDartAvgVal = threeDartAvgVal / realround[1]
    } else {
      (this as any).matchScoreData[this.currentMatch].kamp_saet_scores.map((score: any) => {
        threeDartAvgVal += score.awayPlayerScore
      })
      threeDartAvgVal = threeDartAvgVal / realround[0]
    }

    return Number(threeDartAvgVal).toFixed(2)
  }

  async mounted () : Promise<void> {
    this.getSelectedMatchScore()
  }
}
